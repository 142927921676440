<template>
  <div
    v-if="candidates.fetching === 'resolved'"
    id="wrapper-mobile-size"
  >
    <div class="mobile-size">
      <section>
        <div
          class="wrapper-list-candidate"
        >
          <div
            v-for="candidate in candidates.data"
            :key="candidate.id"
            class="candidate"
          >
            <div class="no-urut">
              {{ candidate.no_urut }}
            </div>
            <img :src="`${candidate.photo}pilih-kandidat.png`">
            <div class="candidate-name">
              {{ candidate.nama }}
            </div>
            <button @click="$router.push(`/candidates/${candidate.id}`)">
              Lihat
            </button>
          </div>
        </div>
      </section>
    </div>
    <ButtonLogoutVue />
  </div>
</template>

<style scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }

    /* .mobile-size section .candidate button {
      right: 0 !important;
    } */
  }

  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

.wrapper-list-candidate {
    padding: 16px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 4.75rem;
    width: 100%;
    height: 100vh;
    align-content: center;
  }

  .candidate {
    width: 24%;
    min-height: 260px;
    background-color: #FFFFFF;
    border-radius: 20px;
    position: relative;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
  }

  .candidate-name {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */
    text-align: center;
    color: #1B1B1B;
  }

  .candidate button {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #FEDC00;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 35px;
    padding: 8px 16px;
    border: none;
    color: #000000;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .candidate .no-urut {
    position: absolute;
    top: 0;
    left: -1px;
    width: 41px;
    height: 22px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #FEDC00;
    opacity: 0.8;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #1B1B1B;
  }

  .candidate img {
    width: 99.9%;
    height: 135px;
  }

</style>

<script>
import API from '@/api'
import ButtonLogoutVue from '@/components/ButtonLogout/ButtonLogout.vue'

export default {
  components: {
    ButtonLogoutVue,
  },
  data() {
    return {
      candidates: {
        data: [],
        fetching: 'idle',
      },
      height: 0,
    }
  },
  created() {
    this.fetchCandidate()
  },
  methods: {
    formatNoUrut(no) {
      if (String(no).length < 2) {
        return `0${no}`
      }
      return no
    },
    async fetchCandidate() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        this.candidates.fetching = 'pending'
        const { data } = await API.evote.publicCandidates()
        this.candidates.data = data
        this.candidates.fetching = 'resolved'
      } catch (error) {
        console.log(error)
        this.candidates.fetching = 'rejected'
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
  },
}
</script>
